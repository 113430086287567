export const requiredPermissions = {
        "importList": [
                "productI18N",
                "product",
                "manufacturer",
                "documentI18N",
                "keyValue",
                "document",
                'component',
                'componentDocument',
                'productDocument',
                'productKeyValue'
        ],
        "exportList": [
                "keyValue",
                "documentI18N",
                "manufacturer",
                "product",
                "productI18N",
                "component",
                "document",
                'componentDocument',
                'productDocument',
                'productKeyValue'
        ]
}